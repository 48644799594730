import _toConsumableArray from "/tmp/workspace/bcrm/oso-platform-front/ci_bcrm_oso-platform-front/src_code/node_modules/@babel/runtime-corejs2/helpers/esm/toConsumableArray.js";
import Vue from "vue";
import VueRouter from "vue-router";
import globalRoutes from "./modules/global";
import adisplays from "./modules/adiaplays/index";
import activity from '@/router/modules/activity/index.js';
import award from '@/router/modules/award/index.js';
import integral from '@/router/modules/integral/index.js';
import shop from '@/router/modules/shop/index.js';
import user from '@/router/modules/user/index.js';
import system from '@/router/modules/system/index.js';
import permission from '@/router/modules/permission/index.js';
import article from '@/router/modules/article/index.js';
import dealer_admin from '@/router/modules/dealer/admin.js';
import dealer from '@/router/modules/dealer/dealer.js';
import promotionPolicy from '@/router/modules/promotionPolicy/index.js';
import orderManage from '@/router/modules/orderManage/index.js';
import accountManage from '@/router/modules/accountManage/index.js';
import dataBoard from '@/router/modules/dataBoard/index.js';
import splitMoney from "@/router/modules/splitMoney/index.js";
import report from "@/router/modules/report/index.js";
Vue.use(VueRouter);
export var constantRoutes = _toConsumableArray(globalRoutes);
export var asyncRoutes = [activity, splitMoney, dataBoard, adisplays, award, integral, shop, user, system, permission, article, dealer_admin, dealer, promotionPolicy, orderManage, accountManage, report];
var createRouter = function createRouter() {
  return new VueRouter({
    // mode: 'history', // require service support
    scrollBehavior: function scrollBehavior() {
      return {
        y: 0
      };
    },
    routes: constantRoutes,
    mode: 'history'
  });
};
var router = createRouter();

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  var newRouter = createRouter();
  // reset router
  router.matcher = newRouter.matcher;
}
export default router;