//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "App",
  data: function data() {
    return {
      // 页面loading展示
      isShowLoading: false,
      // loading文字
      loadingText: "拼命加载中",
      // loading icon
      loadingIcon: "el-icon-loading",
      // 导出提示
      isShowExportTips: false,
      // 导出数据量超出限制
      isShowExportExceedsLimitTips: false
    };
  },
  mounted: function mounted() {
    var _this = this;
    document.getElementById("app").style.display = "block";
    document.getElementById("appLoading").style.display = "none";
    this.$bus.$on("showLoading", function (val) {
      _this.isShowLoading = val.status;
      _this.loadingText = val.text || "加载中...";
      _this.loadingIcon = val.icon || "el-icon-loading";
    });
    this.$bus.$on('showExportTips', function () {
      var flag = localStorage.getItem('showExportTips') === '1'; // 无需显示提示
      if (flag) {
        _this.$message.success('导出任务创建成功，请稍后在报表列表页面或点击右上角下载图标查看和下载；');
      } else {
        _this.isShowExportTips = true;
      }
    });
    this.$bus.$on('showModal', function (key) {
      _this[key] = true;
    });
  },
  methods: {
    closeExportTips: function closeExportTips() {
      this.isShowExportTips = false;
      localStorage.setItem('showExportTips', '1');
    }
  }
};