var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.isShowLoading,
          expression: "isShowLoading",
        },
      ],
      attrs: {
        id: "app",
        "element-loading-text": _vm.loadingText,
        "element-loading-spinner": _vm.loadingIcon,
        "element-loading-background": "rgba(0, 0, 0, 0.8)",
      },
    },
    [
      _c("router-view"),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "导出提示",
            visible: _vm.isShowExportTips,
            width: "45%",
            "show-close": false,
            "close-on-press-escape": false,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.isShowExportTips = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticStyle: { "text-align": "center" } },
            [
              _c("el-image", {
                attrs: {
                  src: "https://carlsbcrm.carlsberg.cn/group1/M00/2F/D6/CuUDO2eHWNiAO7FsAAAMUImEhgA290.png",
                },
              }),
              _vm._v(" "),
              _c("div", { staticStyle: { "margin-top": "10px" } }, [
                _vm._v(
                  "导出任务创建成功，请稍后在报表列表页面或点击右上角下载图标查看和下载；"
                ),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      _vm.isShowExportTips = false
                    },
                  },
                },
                [_vm._v("本次关闭")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "small" },
                  on: { click: _vm.closeExportTips },
                },
                [_vm._v("关闭，不再提示")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "导出提示",
            visible: _vm.isShowExportExceedsLimitTips,
            width: "45%",
            "show-close": false,
            "close-on-press-escape": false,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.isShowExportExceedsLimitTips = $event
            },
          },
        },
        [
          _c("div", { staticStyle: { "text-align": "center" } }, [
            _c(
              "div",
              {
                staticStyle: {
                  "margin-top": "10px",
                  "line-height": "28px",
                  "font-size": "16px",
                },
              },
              [
                _c("div", [
                  _c("em", {
                    staticClass: "el-icon-warning",
                    staticStyle: { color: "#e6a23c", "margin-right": "4px" },
                  }),
                  _vm._v(
                    "数据量超过300万行，批量导出将导致文件过大而无法成功导出！"
                  ),
                ]),
                _vm._v(" "),
                _c("div", [
                  _vm._v("请通过"),
                  _c(
                    "span",
                    {
                      staticStyle: { color: "#00692f", "font-weight": "bold" },
                    },
                    [_vm._v("时间筛选")]
                  ),
                  _vm._v("功能，分多次进行下载。"),
                ]),
              ]
            ),
          ]),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "small" },
                  on: {
                    click: function ($event) {
                      _vm.isShowExportExceedsLimitTips = false
                    },
                  },
                },
                [_vm._v("关闭")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }